import Logoibs from './assets/logo-ibs.png'
import Logofooter from './assets/logofooter.png'
import livreur from './assets/livreur.png'
import bg_image from './assets/bg.png'
import cover from './assets/cover.png'
import section5img from './assets/section5img.png'
import methode from './assets/methode.png'
import image1 from './assets/image1.png'
import image2 from './assets/image2.png'
import image3 from './assets/image3.png'
import image4 from './assets/image4.png'

/*import Logos partenaires*/
import partenaires from './assets/partenaires.png'

/*import random yet */
import facebook from './assets/fb.png'
import Linkedin from './assets/in.png'
import twitter from './assets/t.png'
import Tiktok from './assets/Tiktok_Outline.png'
import moyen from './assets/moyen.png'
import camion from './assets/st.png'
import parc from './assets/parc.png'
import avion from './assets/sf.png'
import workstep from './assets/work-step.png'
import left from './assets/left.png';
import right from './assets/right.png';
import mascotte from './assets/mascot-pCM.png';
import titremascotte from './assets/lepetit-com.png'
import bgmascotte from './assets/bg-commissionaire.png'


/*Card import */
import livraison from './assets/livraison.png'
import logistique from './assets/logistique.png'
import transport from './assets/transport.png'
import demenagement from './assets/demenagement.png'

/*Carre import */
import ico_demenagement from './assets/card/icon-demenagement.png'
import ico_fret from './assets/card/icon-fret.png'
import ico_livraison from './assets/card/icon-livraison.png'
import ico_logistique from './assets/card/icon-logistique.png'
import ico_transport from './assets/card/icon-transport.png'
const icons ={ico_demenagement,ico_fret,ico_livraison,ico_logistique,ico_transport}


const carre={livraison,logistique,transport,demenagement}

const donnee ={parc,partenaires,Logofooter,avion,Logoibs,livreur,bg_image,cover,section5img,methode,image1,image2,image3,image4,facebook,Linkedin,twitter,Tiktok,moyen,camion,left,right,workstep,mascotte,titremascotte,bgmascotte,icons,carre}

export default donnee