import React from 'react'
import './ML.css'

const ML = () => {
  return (
    <>
    <div id='ML'>
      <div className='Centercontent'>
        <h1>Mentions légales</h1>
      
        <h2> Interlogistique Batiment Services</h2>
 
        <h2>Raison sociale :</h2>

        <div>
          Interlogistique Batiment Services (IBS) <br/>
          S.A.R.L au capital de 1 000 000 FCFA <br/>
          <span>RCCM : </span> C.G PNR.RCCM. 07 A 057 <br/>
          <span>NIU : </span> M2014110001679147
        </div>

        <h2> Siège social:</h2>
        <div>
          1249 Rue Vindza -
          Plateau de 15 ans <br/>
          Brazzaville République du Congo <br/>

          Avenue de la base PNR à côté du contrôle technique <br />
          Pointe-Noire République du Congo <br/>
          <span>Email : </span> interlog2007f@yahoo.fr | <br /> <span>Tél.</span> +242 05 300 98 10 /05 307 82 82 <br />

          +242 05 399 88 62 / 05 399 88 65 / <br />
          06 952 92 65 / 06 839 66 62 / <br /> 
          05 399 88 61 / 06 449 39 18 / <br />
        

        </div>
      </div>
    </div>
    </>
  )
}

export default ML